import React, { useEffect, useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import OutlineButton from "../components/OutlineButton";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useAddToHomescreenPrompt } from "../components/AddToHomeScreen";
import { languages } from "../Languages";
import { changeLang } from "../tools";
import { useTranslation } from "react-i18next";

function Splach() {
  const navigate = useNavigate();
  // const langOptions = ["EN", "FR", "NL"];

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Store the selected language in localStorage
  };

  const [langueId, setLangueId] = useState(localStorage.getItem("langue"));
  
  const langOptions = [
    {
      label: "EN",
      value: 0,
    },
    {
      label: "FR",
      value: 1,
    },
    {
      label: "NL",
      value: 2,
    },
  ];

  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  useEffect(() => {
    console.log('localStorage langue =>',localStorage.getItem("langue"))
    if (localStorage.getItem("langue") != null ){
      i18n.changeLanguage('en');
      localStorage.setItem("language", 'en'); // Store the selected language in localStorage
      setLangueId(localStorage.getItem("langue"));

    }
    setLangueId(localStorage.getItem("langue"));

    // console.log('localStorage.getItem langue',localStorage.getItem('langue'))
  }, [localStorage.getItem("langue")]);

  return (
    <div className="mySplash">
      <div className="mySplashBox">
        {/* <button onClick={promptToInstall}>Add to Home Screen</button> */}
        <div className="forgetTopNavBox">
          {/* <img src={FIBack} width={24} height={24} /> */}
          <span className="rankTitle"></span>
          <span className="rankTitle"></span>
          <div className="dFlex">
            <Dropdown
              className="langDrop"
              placeholderClassName="langDropPlaceHolder"
              menuClassName={"langDropMenu"}
              options={langOptions}
              onChange={(e) => {
                // changeLang(e.value);
                e.value === 0
                  ? changeLanguage("en")
                  : e.value === 1
                  ? changeLanguage("fr")
                  : changeLanguage("nl");
              }}
              // value={langOptions[localStorage.getItem('langue')]}
              placeholder={localStorage?.getItem("language")?.toUpperCase()}
            />
          </div>
        </div>
        <div className="myLogoContianer">
          <img className="myLogoImg" alt="airfrancelogo" src={MyLogo} />
        </div>
        <div className="mySplashTextContaner">
          <h3 className="splashText">{t("splash.welcomeTxt")}</h3>
        </div>

        <div className="mySplashCTaContianer">
          <FilledButton
            text={t("splash.create")}
            onClick={() => {
              console.log("We will create an account");
              navigate("/firstsignUp");
            }}
          />
          <OutlineButton
            text={t("splash.login")}
            onClick={() => {
              console.log("We will login");
              navigate("/login");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Splach;
