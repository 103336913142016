import React, { useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import FiEye from "../Images/fi_eye.png";
import FIBack from "../Images/fi_back.png";
import fInfo from "../Images/fi_info.png";
import bottomLogo from "../Images/bottomLogo.png";
import ALertRed from "../Images/alertred.png";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgetPassword() {
  const navigate = useNavigate();
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [myEmail, setMyEmail] = useState('');
  const [errors, setErrors] = useState([]);


  const notify = async () => {
    toast.info("Check your inbox for details.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(1000);
    // navigate("/forgetPasswordForm");
  };

  const resetMyPassword = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(myEmail)) {
      console.log("hello oussama email invalid");
      setErrors({ email: "Email invalide" });
      // setIsLoader(false);
      return;
    } else {
      notify();
    }
  }


  return (
    <div className="mySplash">
      <div className="mySplashBoxFs">
        <div className="forgetTopNavBox maxW700">
          <img
            src={FIBack}
            width={24}
            height={24}
            onClick={() => navigate(-1)}
          />
          <span className="myForgetTitle">Forgot password?</span>
          <span></span>
        </div>

        <div className="rowForget">
          <img className="iconFrgt" src={fInfo} width={20} height={20} />
          <p className="forgetTxt">
            Please enter the email address of your account. We will send you an
            email to reset your password.
          </p>
        </div>

        <div className="mb30">
          <div className="inputBox">
            <h4 className="inputTitle">Professional e-mail address*</h4>
            <input
              className="myEmailInput"
              type="email"
              id="name"
              placeholder="adresse@mail.fr"
              name="email"
              required
              onChange={(e) => {setMyEmail(e.target.value);setErrors([])}}

            />
            {errors.email && (
              <div>
                <span className="input-hint red-text">
                  <img
                    className="pauseInfo"
                    src={ALertRed}
                    width={20}
                    height={20}
                  />
                  Email is invalid. Please enter a valid email address.
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="ctaLoginBox">
          <FilledButton
            text={"Reset password"}
            onClick={() => {
              resetMyPassword()
             
              // navigate('/forgetPasswordForm');
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default ForgetPassword;
