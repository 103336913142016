import React, { useEffect, useState } from "react";
import FIBack from "../../Images/fi_back.png";
import "../HomePages/Style.css";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import FiEye from "../../Images/fi_eye.png";
import ClosedEye from "../../Images/closedEye.png";
import Logout from "../../Images/logout.png";
import FilledButton from "../../components/FilledButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../services";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function ChangeProfil() {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const { t, i18n } = useTranslation();


  const langOptions = [
    {
      label: "EN",
      value: 0,
    },
    {
      label: "FR",
      value: 1,
    },
    {
      label: "NL",
      value: 2,
    },
  ];
  const myDomains = [
    {
      label: "td.klm.com",
      value: 0,
    },
    {
      label: "airfrance.fr",
      value: 1,
    },
    {
      label: "airfranceklm.com",
      value: 2,
    },
    {
      label: "klm.com",
      value: 3,
    },
    {
      label: "transavia.com",
      value: 4,
    },
    {
      label: "fr.transavia.com",
      value: 5,
    },
    {
      label: "klmcargo.com",
      value: 6,
    },
    {
      label: "martinair.com",
      value: 7,
    },
    {
      label: "hop.fr",
      value: 8,
    },
  ];
  const getUserData = () => {
    const user = localStorage.getItem("user");
    // console.log("user", user);
    try {
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  };

  const [myPassword, setMyPassword] = useState("");
  const [myUser, setMyUser] = useState(getUserData());
  const [nothingModified, setNothingModified] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [listOfCompagnies, setListOfCompagnies] = useState([]);

  const [myEmail, setMyEmail] = useState(myUser.email);
  const [myEmailDomaine, setMyEmailDomaine] = useState("office.fr");
  const [myFirstName, setMyFirstName] = useState(myUser.first_name);
  const [myLastName, setMyLastName] = useState(myUser.last_name);
  const [myCompany, setMyCompany] = useState(0);
  const [myDepartement, setMyDepartement] = useState(0);
  const [myLocation, setMyLocation] = useState(0);
  



  const getIndexOfItem = (array,itemId) => {
    return array.find(item => item.value === itemId);
  }
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Store the selected language in localStorage
  };

  let user;
  const getCompagniesList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/compagnies`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          if(item.id === myUser.compagny_id){
            setMyCompany(item.id)
          }
          newAr.push({
            label: item.name,
            value: item.id, 
          });
        });
        setListOfCompagnies(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  const getDepartmentsList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/departments`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          if(item.id === myUser.department_id){
            setMyDepartement(item.id)
          }
          newAr.push({
            label: item.name,
            value: item.id,
          });
        });
        console.log('newArnewAr =>',newAr)
        setListOfDepartments(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  const getCountriesList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/countries`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          if(item.id === myUser.country_id){
            setMyLocation(item.id)
          }
          newAr.push({
            label: item.name,
            value: item.id,
          });
        });
        setListOfCountries(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  const logoutAccount = () => {
    localStorage.removeItem("user");
  };

  const modifMyUser = (myUserId) => {
    setIsLoader(true);
    const data = {
      name: myUser.name,
      email: `${myEmail}@${myEmailDomaine}`,
      first_name: myFirstName,
      last_name: myLastName,
      country_id: myLocation,
      department_id: myDepartement,
      compagny_id: myCompany,
      password:myPassword
    };

    console.log('Meee is =>',myUserId,data)
    axios
      .put(`${API_URL}/users/${myUserId}`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
          "Bearer " +
          myUser.token,
        },
      })
      .then(async  (response) => {
        console.log(response);
        user = getUserData();
        const updatedUser = { ...myUser, ...data };
        localStorage.setItem('user',JSON.stringify(updatedUser));
        setIsLoader(false);
        await notifySuccess();

        navigate("/homepage");
        // navigate("/homepage");
      })
      .catch(async (error) => {
        setIsLoader(false);
        console.log(error.response);
        setErrors(error.response.data.data);
   

        if (error.response.data.data == "The current password does not match our record"){
         await  notify('Password is incorrect');
        }
        // alert(`Login Error: Email address or password is incorrect`);
        // handleApiError(error.response.data.data);
      });
  };

  useEffect(() => {
    getCountriesList();
    getCompagniesList();
    getDepartmentsList();
    user = getUserData();
    if (user != null) {
      setMyUser(user);
      setMyCompany(myUser.compagny_id);
      setMyLocation(myUser.country_id);
      setMyDepartement(myUser.department_id);

      const emailDomaine = myUser.email.split("@")[1];
      const emailText = myUser.email.split("@")[0];

      setMyEmail(emailText);
      setMyEmailDomaine(emailDomaine);
      console.log("my user id", user);
    }
  }, [user]);

  const notify = async (textNotif) => {
    toast.error('Password is incorrect', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(1000);
    // navigate("/forgetPasswordForm");
  };
  const notifySuccess = async (textNotif) => {
    toast.success('Recorded changes.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(2000);
    // navigate("/forgetPasswordForm");
  };

  return (
    <div className="myRankContainer pb100">
        {isLoader && (
          <div className="overlayLoader">
            <Loader
              type="box-rotate-x"
              bgColor={"#091C62"}
              color={"#091C62"}
              // title={"box-rotate-x"}
              size={100}
            />
          </div>
        )}
      <div className="forgetTopNavBox">
        <div className="imgWrapper">
          <img
            src={FIBack}
            width={24}
            height={24}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>

        <span className="rankTitle">{t("modifProfil.title")}</span>
        <div className="langWrapper">
        <Dropdown
              className="langDrop"
              placeholderClassName="langDropPlaceHolder"
              menuClassName={"langDropMenu"}
              options={langOptions}
              onChange={(e) => {
                // changeLang(e.value);
                e.value === 0
                  ? changeLanguage("en")
                  : e.value === 1
                  ? changeLanguage("fr")
                  : changeLanguage("nl");
              }}
              // value={langOptions[localStorage.getItem('langue')]}
              placeholder={localStorage?.getItem("language")?.toUpperCase()}
            />
        </div>
      </div>

      <div className="myCenteredText">
        <span className="userName">{t("modifProfil.usernameTitle")}</span>
        <span className="profileTitle">{myUser.name}</span>
      </div>

      <div>
        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.firstNameTxt")}</h4>
          <input
            className="myEmailInput"
            type="text"
            id="firstName"
            placeholder={myUser.first_name}
            name="firstName"
            required
            value={myFirstName}
            onChange={(e) => setMyFirstName(e.target.value)}
          />
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.lastName")}</h4>
          <input
            className="myEmailInput"
            type="text"
            id="lastName"
            placeholder={myUser.last_name}
            name="lastName"
            required
            value={myLastName}
            onChange={(e) => setMyLastName(e.target.value)}
          />
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.whatCompanyTxt")}</h4>
          <Dropdown
            className="suppBc"
            placeholderClassName="myPlaceHolder"
            menuClassName={"menuClassName"}
            options={listOfCompagnies}
            // onChange={() => {}}
            onChange={(e) => {
              console.log('eeee',e)
              setMyCompany(e.value);
            }}
            // value={myCompany.name}
            value={getIndexOfItem(listOfCompagnies,myCompany)}

            placeholder={t("signup2.whatCompanyPlaceholder")}
          />
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.whatDepartementTxt")}</h4>
          <Dropdown
            className="suppBc"
            placeholderClassName="myPlaceHolder"
            menuClassName={"menuClassName"}
            options={listOfDepartments}
            onChange={(e) => {
              console.log('eeee',e.value)

              setMyDepartement(e.value);
            }}
            // value={defaultOption}
            // value={myDepartement.name}
            value={getIndexOfItem(listOfDepartments,myDepartement)}

            placeholder={t("signup2.whatDepartementTxt")}
          />
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.whatCountryTxt")}</h4>
          <Dropdown
            className="suppBc"
            placeholderClassName="myPlaceHolder"
            menuClassName={"menuClassName"}
            options={listOfCountries}
            onChange={(e) => {
              console.log("eeee =>", e);

              setMyLocation(e.value);
            }}
            value={getIndexOfItem(listOfCountries,myLocation)}
            // value={defaultOption}
            placeholder={t("signup2.whatCountryTxt")}
          />
          {/* {console.log('myCompany =>>><',listOfCountries[myLocation])} */}
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.addTxt")}</h4>
          <div className="myInputsBox">
            <input
              className="myEmailInput inputDemiWidth"
              type="email"
              id="name"
              placeholder={t("modifProfil.addTxt")}
              name="email"
              required
              value={myEmail}
              onChange={(e) => setMyEmail(e.target.value)}
            />
            <Dropdown
              className="myDropDown"
              placeholderClassName="myPlaceHolder"
              menuClassName={"menuClassName"}
              options={myDomains}
              onChange={(e) => {
                console.log("eeee =>", e.value);
                setMyEmailDomaine(e.label);
              }}
              value={myEmailDomaine}
              placeholder="Select an option"
            />
          </div>
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("modifProfil.passTxt")}</h4>
          <div className="myEyeBox">
            <img
              className="myEyePassword"
              src={isPasswordShow ? ClosedEye : FiEye}
              width={20}
              height={20}
              onClick={() => {
                setIsPasswordShow((prev) => !prev);
              }}
            />
            <input
              className="myEmailInput"
              type={isPasswordShow ? "text" : "password"}
              onChange={(e) => setMyPassword(e.target.value)}
              id="password"
              placeholder={t("modifProfil.passTxt")}
              name="password"
              required
            />
          </div>
        </div>
        <div className="forgetPsLinkBox">
          <a
            rel="#"
            className="forgetPsLink"
            onClick={() => {
              navigate("/changepassword");
            }}
          >
            {t("modifProfil.changePassword")}
          </a>
        </div>

        <div className="logoutBox">
          <img src={Logout} width={24} height={24} />
          <a
            rel="#"
            className="forgetPsLink"
            onClick={() => {
              logoutAccount();
              navigate("/login");
              // notify('csd');
            }}
          >
            {t("modifProfil.logout")}
          </a>
        </div>
      </div>

      <div className="ctaLoginBox pb30">
        <FilledButton
          disabled={
            myPassword?.length === 0 ||
            myFirstName?.length === 0 ||
            myLastName?.length === 0 ||
            myEmail?.length === 0 ||
            myEmailDomaine?.length === 0
          }
          text={t("modifProfil.saveChanges")}
          onClick={() => {
            console.log(
              `${myUser.id}${myFirstName}${myLastName}${myEmail}${myEmailDomaine}${myLocation}${myPassword}`
            );
            modifMyUser(myUser.id)
        
            // navigate("/homepage");
          }}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChangeProfil;


