// Routes.js
import React from 'react';
import { createBrowserRouter, Route, Switch,RouterProvider } from 'react-router-dom';
import Login from './pages/Login';
import Splach from './pages/Splach';
import ForgetPassword from './pages/ForgetPassword';
import ForgetPasswordForm from './pages/ForgetPasswordForm';
import FirstSignUp from './pages/FirstSignUp';
import SecondSignUp from './pages/SecondSignUp';
import ResetPopUp from './components/PopUpConfirmation';
import Home from './pages/HomePages/Home';
import RankingPage from './pages/HomePages/RankingPage';
import ChangeProfil from './pages/ProfilePages/ChangeProfil';
import ChangePassword from './pages/ProfilePages/ChangePassword';
import QuizQuestion from './pages/QuizPages/QuizQuestion';
import QuestionExplain from './pages/QuizPages/QuestionExplain';
import QuizLoop from './pages/QuizPages/QuizLoop';
import QuizResult from './pages/QuizPages/QuizResult';
import CampaignList from './pages/QuizPages/CapmaginList';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Splach />,
  },
  {
    path: "/splash",
    element: <Splach />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/forgetPasswordForm",
    element: <ForgetPasswordForm />,
  },

  {
    path: "/resetPopUp",
    element: <ResetPopUp />,
  },

  {
    path: "/firstsignUp",
    element: <FirstSignUp />,
  },

  {
    path: "/secondsignUp",
    element: <SecondSignUp />,
  },

  {
    path: "/homepage",
    element: <Home />,
  },


  {
    path: "/rankingpage",
    element: <RankingPage />,
  },

  {
    path: "/profile",
    element: <ChangeProfil/>,
  },

  {
    path: "/changepassword",
    element: <ChangePassword/>,
  },
  

  {
    path: "/quiz",
    element: <QuizLoop/>,
  },

  {
    path: "/quiz-sessions",
    element: <CampaignList/>,
  },


  {
    path: "/quiz-explain",
    element: <QuestionExplain/>,
  },

  {
    path: "/quiz-result",
    element: <QuizResult/>,
  },


]);


function Routes() {
  return (
    <RouterProvider router={router} />

  );
}

export default Routes;