import React, { useEffect, useState } from "react";
import QuizQuestion from "./QuizQuestion";
import { API_URL } from "../../services";
import axios from "axios";
import QuestionExplain from "./QuestionExplain";
import QuizResult from "./QuizResult";
import { useLocation } from "react-router-dom";
import Modal from "react-responsive-modal";
import ResetPopUp from "../../components/PopUpConfirmation";
import PopUpLeaveQuiz from "../../components/PopUpLeaveQuiz";
import { useNavigate } from 'react-router-dom';
function QuizLoop({}) {
  const [allMyQuizes, setAllMyQuizes] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate()
  // const { allQuizzes,quizzIndex,myCampaign } = state; // Read values passed on state
  const { allQuizzes = [], quizzIndex = 0, myCampaign = {} } = state || {};

  const myUser = JSON.parse(localStorage.getItem("user"));
  const [quizzQuestions, setQuizzQuestions] = useState(allQuizzes[quizzIndex]?.questions || {});
  const [thisQuizzScore, setThisQuizzScore] = useState(0);
  const [howMuchCorrect, setHowMuchCorrect] = useState(0);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    if (!state) {
      // Redirect to a safe route or homepage
      navigate('/');
    }
  }, [state, navigate]);

  // const getAllQuestions = () => {
  //   const headers = {
  //     Authorization: "Bearer " + myUser.token,
  //   };
  //   axios
  //     .get(`${API_URL}/quizzes/${quizzIndex}/data`, {
  //       headers,
  //     })
  //     .then(function (response) {
  //       // setAllMyQuizes();
  //       console.log("getAllQuestions ==>", response.data.data.questions);
  //       setQuizzQuestions(response.data.data.questions);
  //       // navigate("/homepage");
  //     })
  //     .catch(function (error) {
  //       console.log(error.response);
  //       // alert(`Login Error: Email address or password is incorrect`);

  //       // handleApiError(error.response.data.data)
  //     });
  // };

  const [myStep, setMyStep] = useState(0);
  const [questionNbr, setQuestionNbr] = useState(0);

  // const arrayOfQuestions = [
  //   {
  //     id: 0,
  //     questionNbr: 1,
  //     questionTxt:
  //       "In 2023, the Air France-KLM Group was the world's leading Sustainable Aviation Fuel (SAF) user for the second consecutive year?",
  //     options: ["Oussama", "Azmi", "Jago"],
  //     correctOptIndex: 1,
  //     questionExplination: "hello bro how are you , your name is Oussama",
  //   },
  //   {
  //     id: 0,
  //     questionNbr: 1,
  //     questionTxt: "what's your name ?",
  //     options: ["Oussama", "Toukebri", "Touhemi"],
  //     correctOpt: 2,
  //     questionExplination: "hello bro how are you , your name is Oussama",
  //   },
  //   {
  //     id: 0,
  //     questionNbr: 1,
  //     questionTxt: "what's your name ?",
  //     options: ["Oussama", "Toukebri", "Touhemi"],
  //     correctOpt: 2,
  //     questionExplination: "hello bro how are you , your name is Oussama",
  //   },
  // ];

  // useEffect(() => {
  //   console.log("hello there im here", allQuizzes[quizzIndex]?.questions);
  //   // getAllQuestions();
  // }, []);

  return (
    <div>
      {/* {console.log("quizzQuestions?.questions =>>", quizzQuestions)} */}
      {myStep == 0 ? (
        <QuizQuestion
          thisQuestion={quizzQuestions[questionNbr]}
          quizzQuestions={quizzQuestions}
          myStep={myStep}
          setMyStep={setMyStep}
          questionNbr={questionNbr}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          thisQuizzScore={thisQuizzScore}
          setThisQuizzScore={setThisQuizzScore}
          howMuchCorrect={howMuchCorrect}
          setHowMuchCorrect={setHowMuchCorrect}
          myCampaign={myCampaign}
        />
      ) : myStep == 1 ? (
        <QuestionExplain
          thisQuestion={quizzQuestions[questionNbr]}
          quizzQuestions={quizzQuestions}
          myStep={myStep}
          setMyStep={setMyStep}
          setQuestionNbr={setQuestionNbr}
          questionNbr={questionNbr}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          myCampaign={myCampaign}
        />
      ) : (
        <QuizResult
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          questionNbr={questionNbr}
          thisQuizzScore={thisQuizzScore}
          setThisQuizzScore={setThisQuizzScore}
          howMuchCorrect={howMuchCorrect}
          setHowMuchCorrect={setHowMuchCorrect}
          myCampaign={myCampaign}
        />
      )}
      <Modal open={open} onClose={onCloseModal} center>
        {/* <h2>Simple centered modal</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
          hendrerit risus, sed porttitor quam.
        </p> */}
        <PopUpLeaveQuiz onCloseModal={onCloseModal} />
      </Modal>
    </div>
  );
}

export default QuizLoop;
