import React, { useState } from "react";
import FiX from "../../Images/fi_x.png";
import QuizImage from "../../Images/quizimg.png";
import bottomLogo from "../../Images/bottomLogo.png";

import "../Style.css";
import FilledButton from "../../components/FilledButton";

import { useNavigate } from "react-router-dom";
import { t } from "i18next";

function QuizResult({
  onOpenModal,
  onCloseModal,
  questionNbr,
  thisQuizzScore,
  setThisQuizzScore,
  howMuchCorrect,
  setHowMuchCorrect,
  myCampaign,
}) {
  const navigate = useNavigate();
console.log("myCampaign",myCampaign)
  return (
    <div className="mySplash my-flex">
      <div>
        <div className="forgetTopNavBox">
          <span className="w15perc"></span>
          <span className="myForgetTitle myQuizTitle">{t("quizListPage.resultTitle")}</span>
          <div className="w15perc">
            <img
              src={FiX}
              width={24}
              height={24}
              onClick={() => {
                onOpenModal();
                // navigate(-1);
                // navigate("/login");
              }}
            />
          </div>
        </div>

        <div className="topResultBox">
          <span>
          {t("home.campaign")} # {myCampaign?.id} - {myCampaign?.name}
          </span>
          {/* <span>General culture</span> */}
          <h5>{howMuchCorrect > 2 ? "Well done!" : "Almost!"}</h5>
          <span className="my-correcto">
            {howMuchCorrect}/{questionNbr + 1} {t("quizListPage.correct")}
          </span>
        </div>

        <div className="pointsBox">
          <span>{t("quizListPage.youHaveEarned")}</span>
          <span className="pointsTxt">+{thisQuizzScore} {t("quizListPage.points")}</span>
        </div>

        <div></div>

        <div className="ctaLoginBox">
          <FilledButton
            text={t("quizListPage.goToRank")}
            onClick={() => {
              console.log("go go upp --");
              navigate("/rankingpage");

              // checkAnswer();
            }}
          />
          <div className="mt20">
            <a
              rel="#"
              className="forgetPsLink"
              onClick={() => {
                navigate("/homepage");
              }}
            >
              {t("quizListPage.backHome")}
            </a>
          </div>
        </div>
      </div>
      <img className="mylogoBtm" src={bottomLogo} width={240} height={240} />
    </div>
  );
}

export default QuizResult;
