import React, { useEffect, useState } from "react";
import MyLogo from "../../Images/myLogo.png";
import UserImg from "../../Images/fi_user.png";
import FiClock from "../../Images/fi_clock.png";

import FilledButton from "../../components/FilledButton";
import OutlineButton from "../../components/OutlineButton";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import axios from "axios";
import { API_URL } from "../../services";
import { useTranslation } from "react-i18next";

function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const langOptions = [
    {
      label: "EN",
      value: 0,
    },
    {
      label: "FR",
      value: 1,
    },
    {
      label: "NL",
      value: 2,
    },
  ];
  const myUser = JSON.parse(localStorage.getItem("user"));
  const [myQuizzes, setMyQuizzes] = useState([]);
  const [myCampaign, setMyCampaign] = useState([]);
  const [ongoingQuizzes, setOngoingQuizzes] = useState("");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Store the selected language in localStorage
  };

  const getAllQuizzes = async () => {
    console.log("Get All Quizzes", myUser?.token);
    const headers = {
      Authorization: "Bearer " + myUser?.token,
    };
    axios
      .get(`${API_URL}/campaigns`, {
        headers,
      })
      .then(function (response) {
        // setAllMyQuizes();
        console.log(
          "response.data.data.quizzes",
          response.data.data[0]?.quizzes
        );
        if (response.data?.data.length > 0) {
          setMyCampaign(response.data?.data[0]);
          setMyQuizzes(response.data?.data[0]?.quizzes);
          // Extract ongoing quizzes
          try {
            //  = response?.data?.data[0]?.flatMap((campaign) =>
            // const ongoing  = response.data?.data[0]?.quizzes.filter((quiz) => quiz.isOngoing)
            const ongoing = response.data?.data[0]?.quizzes.filter(
              (quiz) => quiz.isOngoing
            );
            console.log("ongoing", ongoing[0]);
            if (ongoing) {
              setOngoingQuizzes(ongoing[0]);
            }
          } catch (error) {
            console.log("error setOngoingQuizzes", error);
          }
        }

        // navigate("/homepage");
      })
      .catch(function (error) {
        // console.log(error.response.data.data);
        console.log("Errorrrr", error);

        // alert(`Login Error: Email address or password is incorrect`);

        // handleApiError(error.response.data.data)
      });
  };

  useEffect(() => {
    getAllQuizzes().then(() => {
      // console.log("MY QUizzes is =>", ongoingQuizzes);
    });
  }, []);

  return (
    <div className="myContainer">
      <div className="subContainer">
        <div className="forgetTopNavBox">
          {/* <img src={FIBack} width={24} height={24} /> */}
          <span className="rankTitle"></span>
          <span className="rankTitle"></span>
          <div className="dFlex">
            <div
              className="bgMarron mR10"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <img src={UserImg} width={24} height={24} />
            </div>

            <Dropdown
              className="langDrop"
              placeholderClassName="langDropPlaceHolder"
              menuClassName={"langDropMenu"}
              options={langOptions}
              onChange={(e) => {
                // changeLang(e.value);
                e.value === 0
                  ? changeLanguage("en")
                  : e.value === 1
                  ? changeLanguage("fr")
                  : changeLanguage("nl");
              }}
              // value={langOptions[localStorage.getItem('langue')]}
              placeholder={localStorage?.getItem("language")?.toUpperCase()}
            />
          </div>
        </div>

        <div className="myLogoContianer">
          <img className="myLogoImg" alt="airfrancelogo" src={MyLogo} />
        </div>

        <div className="homePage">
          <h2 className="homeTitle">{t("home.title")}</h2>
          <p>{t("home.longTxt")}</p>
          {myQuizzes.length > 0 && (
            <div className="mgtop50 mb20">
              <h1>
              {t("home.campaign")} # {myCampaign?.id} - {i18n.language == 'en' ? myCampaign?.name : i18n.language == 'fr' ? myCampaign?.name_fr : myCampaign?.name_nl }
              </h1>
              {/* <div    style={{ backgroundImage: `url(${"https://af.lebackyard.ovh/storage/"+item.pic_url || ''})` }} className="quizBackgroundImg"> */}
              {/* {console.log('ongoingQuizzes',ongoingQuizzes)} */}
              <div
                style={{
                  backgroundImage:
                    ongoingQuizzes != ""
                      ? `url(${
                          "https://app.20years.airfranceklm.com/storage/" +
                            ongoingQuizzes?.pic_url || ""
                        })`
                      : `url('../Images/attachement.png')`,
                }}
                className="quizBackgroundImg"
              >
                <div>
                  <img
                    className=""
                    alt="airfrancelogo"
                    width={20}
                    height={20}
                    src={FiClock}
                  />
                  <span>{myCampaign?.time_remaining}</span>
                </div>
              </div>
              <p>
              {t("home.longTxt2")}
              </p>
            </div>
          )}
        </div>
        <div className="myHomeCTaContianer">
          <FilledButton
            disabled={myQuizzes.length === 0}
            text={t("home.discoverBtn")}
            onClick={() => {
              console.log("We will create an account");
              navigate("/quiz-sessions", {
                state: { myCampaign: myCampaign, myQuizzes: myQuizzes },
              });
            }}
          />
          <OutlineButton
            text={t("home.goRank")}
            onClick={() => {
              console.log("We will login");
              navigate("/rankingpage");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
