import React, { useState } from "react";
import FIBack from "../../Images/fi_back.png";
import "../HomePages/Style.css";
import FiEye from "../../Images/fi_eye.png";
import ClosedEye from "../../Images/closedEye.png";

import { useNavigate } from "react-router-dom";
import FilledButton from "../../components/FilledButton";
import { API_URL } from "../../services";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
import resetPsImg from "../../Images/resetPs.png";
import PauseInfo from "../../Images/fipause.png";
import Modal from "react-responsive-modal";
import ResetPopUp from "../../components/PopUpConfirmation";
import { t } from "i18next";


function ChangePassword() {
  const navigate = useNavigate();
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const [showcurrentPassword, setShowCurrentPassword] = useState(false);
  const [shownewPassword, setShowNewPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);

  const changePassword = () => {

  const myUser = JSON.parse(localStorage.getItem("user"));


    setIsLoader(true)
    const data = {
      current_password:currentPassword,
      new_password:newPassword,
      new_password_confirmation:confirmPassword
    };

    axios
      .post(`${API_URL}/change_password`, data, {
        headers: {
          Authorization: "Bearer " + myUser?.token,
        },
      })
      .then( async(response) => {
        // localStorage.setItem('user', JSON.stringify(response.data.data));
        console.log(response.data.data);
        await notifySuccess();
        navigate("/homepage");
        setIsLoader(false)
        // User password changed successfully.
      })
      .catch(async (error) => {
        console.log(error.response.data.data);
        if (error?.response?.data?.data?.new_password =='The new password confirmation does not match.'){
          await notify()
        }else if (error?.response?.data?.data == 'The current password does not match our record'){
          await notify2()
        }
        setIsLoader(false)
        // handleApiError(error.response.data.data);
      });
  }

// The current password does not match our record
  const notify = async (textNotif) => {
    toast.error(t("changePassword.erroridentique"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(1000);
    // navigate("/forgetPasswordForm");
  };

  const notify2 = async (textNotif) => {
    toast.error(t("changePassword.errorPassword"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(1000);
    // navigate("/forgetPasswordForm");
  };
  const notifySuccess = async (textNotif) => {
    toast.success(t("changePassword.toastSuccess"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    await delay(2000);
    // navigate("/forgetPasswordForm");
  };

  function validatePassword(password) {
    // Check length
    if (password.length <= 8) {
      return false;
      // return "Password needs more than 8 characters.";
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
      // return "Password needs at least one uppercase letter.";
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
      // return "Password needs at least one lowercase letter.";
    }

    // Check for at least one number or special character
    if (!/[0-9!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false;
      // return "Password must contain a number or special character.";
    }

    return true;
    // If all conditions are metﬂ
  }
  
  return (
    <div className="myRankContainer pb100">
      
    
          {isLoader && (
          <div className="overlayLoader">
          <Loader
            type="box-rotate-x"
            bgColor={"#091C62"}
            color={"#091C62"}
            // title={"box-rotate-x"}
            size={100}
          />
        </div>
          )}
      <div className="forgetTopNavBox">
        <img src={FIBack} width={24} height={24} onClick={() => navigate(-1)} />
        <span className="myForgetTitle">{t("changePassword.changeTitle")}</span>
        <span></span>
      </div>
      <div>
        <div className="inputBox">
          <h4 className="inputTitle">{t("changePassword.currentPassTitle")}</h4>
          <div className="myEyeBox">
          <img className="myEyePassword" src={showcurrentPassword ?  ClosedEye  : FiEye} width={20} height={20} onClick={()=>{setShowCurrentPassword(!showcurrentPassword)}} />
            <input
              className="myEmailInput"
              type={showcurrentPassword ? "text" : "password"}
              id="password"
              placeholder={t("changePassword.currentPassPlace")}
              name="password"
              required
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("changePassword.newPassTitle")}</h4>
          <div className="myEyeBox">
          <img className="myEyePassword" src={shownewPassword ?  ClosedEye  : FiEye} width={20} height={20} onClick={()=>{setShowNewPassword(!shownewPassword)}} />
            <input
              className="myEmailInput"
              type={shownewPassword ? "text" : "password"}
              id="password"
              placeholder={t("changePassword.newPassPlace")}
              name="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">{t("changePassword.confirmTitle")}</h4>
          <div className="myEyeBox">
            <img className="myEyePassword" src={showconfirmPassword ?  ClosedEye  : FiEye} width={20} height={20} onClick={()=>{setShowConfirmPassword(!showconfirmPassword)}} />
            <input
              className="myEmailInput"
              type={showconfirmPassword ? "text" : "password"}
              id="password"
              placeholder={t("changePassword.confirmTxtPlace")}
              name="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {newPassword.length > 0 && (
                <div>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={newPassword.length > 8 ? resetPsImg : PauseInfo}
                      width={16}
                      height={16}
                    />
                    {t("signup.hint3")}
                  </span>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={
                        /[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword)
                          ? resetPsImg
                          : PauseInfo
                      }
                      width={16}
                      height={16}
                    />
                    {t("signup.hint4")}
                  </span>
                  <span className="input-hint">
                    <img
                      className="pauseInfo"
                      src={
                        /[0-9!@#$%^&*(),.?":{}|<>]/.test(newPassword)
                          ? resetPsImg
                          : PauseInfo
                      }
                      width={16}
                      height={16}
                    />
                    {t("signup.hint5")}
                  </span>
                </div>
              )}
        </div>
      </div>

      <div className="ctaLoginBox">
        <FilledButton disabled={currentPassword?.length ==0 || newPassword?.length ==0 || confirmPassword?.length ==0 || !validatePassword(newPassword)} text={t("changePassword.setBtn")}
        onClick={() => {changePassword()}} 
        />
        <div className="mt20">
          <a
            rel="#"
            className="forgetPsLink"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("changePassword.cancel")}
          </a>
        </div>
      </div>
      <ToastContainer />
      {/* <div>
      <Modal open={open} onClose={onCloseModal} center>
        {/* <h2>Simple centered modal</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
          hendrerit risus, sed porttitor quam.
        </p> 
        <ResetPopUp />
      </Modal>
    </div> */}
    </div>
  );
}

export default ChangePassword;
