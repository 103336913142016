import React from "react";
import "./Style.css";

const FilledButton = ({ text, onClick,disabled }) => {
  return (
    <button disabled={disabled} className={ `filledBtn ${disabled ? 'disabled' : ''}`} type="button" onClick={onClick}>
      {text}
    </button>
  );
}

export default FilledButton;
