import React from "react";
import "./Style.css";

const OutlineButton = ({ text, onClick }) => {
  return (
    <button className="outlineBtn" type="button" onClick={onClick}>
      {text}
    </button>
  );
}

export default OutlineButton;
