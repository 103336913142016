import React, { useEffect, useState } from "react";
import FIBack from "../../Images/fi_back.png";
import { useNavigate, useLocation } from "react-router-dom";
import UserImg from "../../Images/fi_user.png";
import FiClock from "../../Images/fi_clock.png";
import axios from "axios";
import { API_URL } from "../../services";
import FilledButton from "../../components/FilledButton";
import OutlineButton from "../../components/OutlineButton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function CampaignList() {
  const { t, i18n } = useTranslation();

  const { state } = useLocation();
  const { myCampaign, myQuizzes } = state; // Read values passed on state
  const navigate = useNavigate();
  const myUser = JSON.parse(localStorage.getItem("user"));
  const [myList, setMyList] = useState([]);
  // const [myQuizzes, setMyQuizzes] = useState([]);

  //   const getRankingList = () => {
  //     const headers = {
  //       Authorization:
  //         "Bearer " +
  //         myUser.token,
  //     };
  //     const newAr = [];
  //     axios
  //       .get(`${API_URL}/top_gamers`, {
  //         headers
  //       })
  //       .then(function (response) {
  //         console.log('My List response =>',response.data.data);

  //         // (response.data.data).map((item,index)=> {
  //         //   newAr.push({
  //         //     label:item.name,
  //         //     value:item.id
  //         //   })
  //         // });
  //         setMyList(response.data.data);
  //       })
  //       .catch(function (error) {
  //         console.log(error.response.data);
  //         // alert(`Login Error: Email address or password is incorrect`);
  //       });
  //   };

  //   useEffect(() => {
  //     console.log('dffdddf')
  //     getRankingList()
  //   }, [])

  useEffect(() => {
    console.log("myQuizzes =>>", myQuizzes);
  }, []);

  return (
    <div className="myCampaignContainer">
      <div className="forgetTopNavBox">
        <img
          src={FIBack}
          width={24}
          height={24}
          onClick={() => {
            navigate("/homepage");
          }}
        />
        <span className="rankTitle">
          {i18n.language == "en"
            ? myCampaign?.name
            : i18n.language == "fr"
            ? myCampaign?.name_fr
            : myCampaign?.name_nl}
        </span>
        <span></span>
      </div>
      {myQuizzes.map((item, index) => {
        return (
          <>
            <div className="quizzList">
              <div className="mgtop50 mb20">
                <h1>
                  {t("quizListPage.session")} #{index + 1}{" "}
                  {i18n.language == "en"
                    ? item?.name
                    : i18n.language == "fr"
                    ? item?.name_fr
                    : item?.name_nl}
                </h1>
                {/* background-image: url('../Images/attachement.png'); */}

                <div
                  style={{
                    backgroundImage: `url(${
                      "https://app.20years.airfranceklm.com/storage/" + item.pic_url || ""
                    })`,
                  }}
                  className="quizBackgroundImg"
                >
                  <div>
                    <img
                      className=""
                      alt="airfrancelogo"
                      width={20}
                      height={20}
                      src={FiClock}
                    />
                    <span>{item?.time_remaining}</span>
                  </div>
                </div>
                <p className="descText">
                  {i18n.language == "en"
                    ? item?.description
                    : i18n.language == "fr"
                    ? item?.description_fr
                    : item?.description_nl}
                </p>
              </div>
            </div>
            <div className="compaignListCTaContianer">
              <FilledButton
                text={t("quizListPage.completeBtn")}
                disabled={
                  myQuizzes[index].questions.length == 0 ||
                  !myQuizzes[index].isOngoing
                }
                onClick={() => {
                  console.log("We will create an account");
                  navigate("/quiz", {
                    state: {
                      allQuizzes: myQuizzes,
                      quizzIndex: index,
                      myCampaign: myCampaign,
                    },
                  });
                }}
              />
            </div>
          </>
        );
      })}
    </div>
  );
}

export default CampaignList;
