import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locals/en.json";
import nl from "./locals/nl.json";
import fr from "./locals/fr.json";

// Detect the user's language preference from localStorage
const savedLanguage = localStorage.getItem("language") || "en";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      nl: { translation: nl },
      fr: { translation: fr },
    },
    lng: savedLanguage, // Default language
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false, // React already safe from XSS
    },
  });

// Set language in local storage if not already set
if (!localStorage.getItem("language")) {
  localStorage.setItem("language", savedLanguage);
}

export default i18n;
