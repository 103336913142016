import React, { useEffect, useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import FiEye from "../Images/fi_eye.png";
import FIBack from "../Images/fi_back.png";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../services";
import Loader from "react-js-loader";

import bottomLogo from "../Images/bottomLogo.png";
import { t } from "i18next";

function SecondSignUp({}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email, emailDomaine, username, password } = state;

  const options = ["Google", "Facebook Meta", "Air France"];
  const defaultOption = options[0];
  const url = "https://af.lebackyard.ovh/api/register";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfCompagnies, setListOfCompagnies] = useState([]);
  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  // const [firstName, setFirstName] = useState('');

  useEffect(() => {
    console.log("email =>", `${email}@${emailDomaine}`);
    console.log("emailDomaine =>", emailDomaine);
    console.log("username =>", username);
    console.log("password =>", password);
  }, []);

  useEffect(() => {
    getCountriesList();
    getCompagniesList();
    getDepartmentsList();
    // const user = getUserData();
    // if (user != null) {
    //   setMyUser(user);
    //   console.log("my user id", user);
    // }
  }, []);

  const handleApiError = (error) => {
    // If the error is a response error
    if (error) {
      const errorDetails = Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
      alert(`SignUp Error:\n${errorDetails}`);
    } else {
      console.log('my error',error)
      // For other errors
      // alert(`Error: ${error}`);
    }
  };

  const createAccount = () => {
    console.log('response.data.data',location,listOfCountries[location-1]);
    // return; 
    setIsLoader(true);
    const data = {
      first_name: firstName,
      name: username,
      last_name: lastName,
      compagny_id: company,
      department_id: department,
      country_id: location,
      email: `${email}@${emailDomaine}`,
      password: password,
    };

    axios
      .post(`${API_URL}/register`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
     
        console.log(response.data.data);
        setIsLoader(false);

        navigate("/homepage");
      })
      .catch(function (error) {
        setIsLoader(false);
        console.log("error.response.data.data",error.response.data);
        handleApiError(error.response.data.data);
        console.log('zerrorrrrrr =>',error)
      });
  };

  const getCountriesList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/countries`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          newAr.push({
            label: item.name,
            value: item.id,
            // index:index
          });
        });
        setListOfCountries(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  const getCompagniesList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/compagnies`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          newAr.push({
            label: item.name,
            value: item.id,
          });
        });
        setListOfCompagnies(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  const getDepartmentsList = () => {
    const newAr = [];
    axios
      .get(`${API_URL}/departments`, {
        // headers,
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          newAr.push({
            label: item.name,
            value: item.id,
            // index:index
          });
        });
        setListOfDepartments(newAr);
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        alert(`Login Error: Email address or password is incorrect`);
      });
  };

  return (
    <div className="mySplash">
      {isLoader && (
        <div className="overlayLoader">
          <Loader
            type="box-rotate-x"
            bgColor={"#091C62"}
            color={"#091C62"}
            // title={"box-rotate-x"}
            size={100}
          />
        </div>
      )}
      <div className="mySplashBoxFs">
        <div className="topNavBox navWithSteps">
          <img
            onClick={() => navigate(-1)}
            src={FIBack}
            width={24}
            height={24}
          />
          <div className="myStepIndicatorBox">
            <div className="myStepIndicator"></div>
            <div className="myStepIndicator"></div>
          </div>
          <div></div>
        </div>

        <div>
          <h2 className="inputTitle maxW700">{t('signup2.title')}</h2>

          <div className="inputBox">
            <h4 className="inputTitle">{t('signup2.firstName')}</h4>
            <div className="myInputsBox">
              <input
                className="myEmailInput"
                type="text"
                id="firstName"
                placeholder={t('signup2.firstName')}
                name="text"
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="inputBox">
            <h4 className="inputTitle">{t('signup2.lastName')}</h4>
            <input
              className="myEmailInput"
              type="text"
              id="lastName"
              placeholder={t('signup2.lastName')}
              name="lastName"
              required
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="inputBox">
            <h4 className="inputTitle">{t('signup2.whatCompanyTxt')}</h4>
            <Dropdown
              className="suppBc"
              placeholderClassName="myPlaceHolder"
              menuClassName={"menuClassName"}
              options={listOfCompagnies}
              onChange={(e) => {
                setCompany(e.value);
              }}
              placeholder={t('signup2.whatCompanyPlaceholder')}
            />
          </div>

          <div className="inputBox">
            <h4 className="inputTitle">{t('signup2.whatDepartementTxt')}</h4>
            <Dropdown
              className="suppBc"
              placeholderClassName="myPlaceHolder"
              menuClassName={"menuClassName"}
              options={listOfDepartments}
              onChange={(e) => {
                setDepartment(e.value);
              }}
              placeholder={t('signup2.whatDepartementPlaceholder')}
            />
          </div>

          <div className="inputBox">
            <h4 className="inputTitle">{t('signup2.whatCountryTxt')}</h4>
            <Dropdown
              className="suppBc"
              placeholderClassName="myPlaceHolder"
              menuClassName={"menuClassName"}
              options={listOfCountries}
              onChange={(e) => {
                console.log('eee =>',e)
                setLocation(e.value);
              }}
              placeholder={t('signup2.whatCountryPlaceholder')}
            />
          </div>
        </div>

        <div className="BtmSpanBox maxW700">
          <span className="BtmSpan ">
          {t('signup2.privacy')} <a>{t('signup2.privacy2')}</a>{" "}
          {t('signup2.privacy3')} <a onClick={() => {}}>{t('signup2.privacy4')}</a>
          </span>
        </div>
        <div className="ctaLoginBox">
          <FilledButton
            disabled={
              firstName.length == 0 ||
              lastName.length == 0 ||
              company.length == 0 ||
              department.length == 0 ||
              location.length == 0
            }
            text={t('signup2.btnTxt')}
            onClick={() => {
              createAccount();
            }}
          />
        </div>
        {/* 
      <div className="bottomCtaBox">
        <span className="bottomCtaTxt">Already have an account?</span>
        <a className="forgetPsLink">Login</a>
      </div> */}
      </div>
      <img className="mylogoBtm" src={bottomLogo} width={240} height={240} />
    </div>
  );
}

export default SecondSignUp;



// "SQLSTATE[23000]: Integrity constraint violation: 1452 Cannot add or update a child row: a foreign key constraint fails (`admin_af`.`users`, CONSTRAINT `users_country_id_foreign` FOREIGN KEY (`country_id`) REFERENCES `countries` (`id`) ON DELETE SET NULL) (SQL: insert into `users` (`first_name`, `name`, `last_name`, `compagny_id`, `department_id`, `country_id`, `email`, `password`, `updated_at`, `created_at`) values (pour, pourmoi, An, 6, 6, 0, pour@td.klm.com, $2y$10$6FXx2yS29znPEwUhMez35uCDeRAk/V8naAKLy.fodsLY.28tyDNpC, 2024-06-20 15:31:24, 2024-06-20 15:31:24))"



// "SQLSTATE[23000]: Integrity constraint violation: 1452 Cannot add or update a child row: a foreign key constraint fails (`admin_af`.`users`, CONSTRAINT `users_country_id_foreign` FOREIGN KEY (`country_id`) REFERENCES `countries` (`id`) ON DELETE SET NULL) (SQL: insert into `users` (`first_name`, `name`, `last_name`, `compagny_id`, `department_id`, `country_id`, `email`, `password`, `updated_at`, `created_at`) values (hello, qs, qsd, 0, 0, 0, q@airfrance.fr, $2y$10$6wDGO35aNSbAMBq3g4kCZeOvckFsfcX.o6H9196tTOWs0D9pyIwoW, 2024-06-20 15:36:35, 2024-06-20 15:36:35))"




// "SQLSTATE[23000]: Integrity constraint violation: 1452 Cannot add or update a child row: a foreign key constraint fails (`admin_af`.`users`, CONSTRAINT `users_department_id_foreign` FOREIGN KEY (`department_id`) REFERENCES `departments` (`id`) ON DELETE SET NULL) (SQL: insert into `users` (`first_name`, `name`, `last_name`, `compagny_id`, `department_id`, `country_id`, `email`, `password`, `updated_at`, `created_at`) values (qsd, qs, qsd, 1, 0, 1, q@airfrance.fr, $2y$10$861US7L2umQSviP0tNX6weYY4qkvAtuuybHHFAXOoq2.F1XLVyfKq, 2024-06-20 15:46:17, 2024-06-20 15:46:17))"

// "SQLSTATE[23000]: Integrity constraint violation: 1452 Cannot add or update a child row: a foreign key constraint fails (`admin_af`.`users`, CONSTRAINT `users_department_id_foreign` FOREIGN KEY (`department_id`) REFERENCES `departments` (`id`) ON DELETE SET NULL) (SQL: insert into `users` (`first_name`, `name`, `last_name`, `compagny_id`, `department_id`, `country_id`, `email`, `password`, `updated_at`, `created_at`) values (qsd, qs, qsd, 1, 0, 2, q@airfrance.fr, $2y$10$QtIMC8nFMdtwsxMJ1jsU0uSgtpiyKejDi7CuHgJZdR2b95C0RYibi, 2024-06-20 15:46:51, 2024-06-20 15:46:51))"