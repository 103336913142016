import React, { useState } from "react";
import "./Style.css";
import MyLogo from "../Images/myLogo.png";
import FilledButton from "../components/FilledButton";
import FiEye from "../Images/fi_eye.png";
import FIBack from "../Images/fi_back.png";
import fInfo from "../Images/fi_info.png";
import { useNavigate } from "react-router-dom";
import FiX from "../Images/fi_x.png";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ResetPopUp from "../components/PopUpConfirmation";



function ForgetPasswordForm() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="mySplash">
      <div className="forgetTopNavBox">
        <span></span>
        <span className="myForgetTitle">Forgot password?</span>
        <img src={FiX} width={24} height={24} onClick={()=>{navigate('/login')}}/>
      </div>


      <div>

        <div className="inputBox">
          <h4 className="inputTitle">New password*</h4>
          <div className="myEyeBox">
            <img className="myEyePassword" src={FiEye} width={20} height={20} />
            <input
              className="myEmailInput"
              type="password"
              id="password"
              placeholder="new password"
              name="password"
              required
            />
          </div>
        </div>

        <div className="inputBox">
          <h4 className="inputTitle">Confirm the new password*</h4>
          <div className="myEyeBox">
            <img className="myEyePassword" src={FiEye} width={20} height={20} />
            <input
              className="myEmailInput"
              type="password"
              id="password"
              placeholder="confirm the password"
              name="password"
              required
            />
          </div>
        </div>
      </div>

      <div className="ctaLoginBox">
        <FilledButton text={"Set new password"} onClick={() => {onOpenModal()}} />
      </div>

      <div>
      <Modal open={open} onClose={onCloseModal} center>
        {/* <h2>Simple centered modal</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
          hendrerit risus, sed porttitor quam.
        </p> */}
        <ResetPopUp />
      </Modal>
    </div>

    </div>
  );
}

export default ForgetPasswordForm;
