import { useEffect } from "react";
import "./App.css";
import { useAddToHomescreenPrompt } from "./components/AddToHomeScreen";
import Routes from "./routes";
import "./i18n"; // Import the i18n configuration
import i18n from "./i18n";

function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  // const language = localStorage.getItem('language') || 'en';
  // useEffect(() => {

  //   if (!localStorage.getItem('language')) {
  //     localStorage.setItem('language', language);
  //     i18n.changeLanguage("en");

  //     console.log('Language set to default: en');
  //   } else {
  //     console.log('Language already set:', localStorage.getItem('language'));
  //   }
  //   // console.log('localStorage.getItem langue',localStorage.getItem('langue'))
  // }, [language]);

  return <Routes />;
}

export default App;
