import React from "react";
import FIBack from "../Images/fi_back.png";
import FilledButton from "./FilledButton";
import FiX from "../Images/fi_x.png";
import resetPsImg from "../Images/resetPs.png";
import { useNavigate } from "react-router-dom";


function ResetPopUp() {
  const navigate = useNavigate();

  return (
    <div className="myContainer mxw700auto">
      {/* <div className="topNavRight">
        <img src={FiX} width={24} height={24} />
      </div> */}

      <div className="myCenterDiv mgBtm20">
        <img src={resetPsImg} width={60} height={60} />
      </div>
      <div className="myCenterDiv">
        <h2>Your new password has been saved!</h2>
        <p>You can now login using your new password.</p>
      </div>

      <div className="ctaLoginBox">
        <FilledButton text={"Continue to login"} onClick={() => {navigate('/login')}} />
      </div>
    </div>
  );
}

export default ResetPopUp;
